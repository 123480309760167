<template>
  <div>
    <b-modal
      ref="add-by-bill-modal"
      hide-footer
      title="Thêm mới cộng trừ khác hoa hồng cửa hàng theo hóa đơn"
    >
      <v-form ref="form" lazy-validation>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Mã hóa đơn:"
              label-align-md="left"
              class="required-control"
            >
              <b-row>
                <b-col cols="8">
                  <b-input
                    type="text"
                    size="sm"
                    class="input-style"
                    v-model="mainModel.billNumber"
                    v-on:keyup.enter="getEmployeeInBill()"
                  ></b-input>
                </b-col>
                <b-col cols="4">
                  <b-button
                    class="font-weight-bolder"
                    variant="primary"
                    @click="getEmployeeInBill"
                    ><i class="fas fa-search text-white fa-lg"></i
                  ></b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-form-group label-align-md="left" label="Ngày" class="col-md-6">
            <date-picker
              placeholder="Ngày"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="mainModel.date"
            ></date-picker>
          </b-form-group>
          <b-form-group
            label="Nguồn từ:"
            label-align-md="left"
            class="col-md-6"
          >
            <b-form-select
              v-model="mainModel.sourceType"
              size="sm"
              @change="assignValue"
              class="select-style"
            >
              <b-form-select-option value="1"> Khác </b-form-select-option>
              <b-form-select-option value="2"> CSKH </b-form-select-option>
              <b-form-select-option value="3"> QA </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-row>
              <b-col>
                <b-form-group
                  label="Loại:"
                  label-align-md="left"
                  class="required-control"
                >
                  <b-form-select
                    class="select-style"
                    v-model="mainModel.operatorType"
                    size="sm"
                  >
                    <b-form-select-option value="1">
                      Khen
                    </b-form-select-option>
                    <b-form-select-option value="2">
                      Hài lòng
                    </b-form-select-option>
                    <b-form-select-option value="3">
                      Không hài lòng
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Giá trị:"
              label-align-md="left"
              class="required-control"
            >
              <b-input
                type="text"
                size="sm"
                class="input-style text-right"
                v-model="mainModel.amount"
                v-mask="mask"
                @change="assignValue"
              ></b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Mô tả">
              <b-form-textarea
                v-model="mainModel.description"
                placeholder="Nhập mô tả"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Nhân viên:" class="col-md-6 pl-0">
          <Autosuggest
            :model="searchEmployee"
            :suggestions="filteredOptionEmployee"
            placeholder="nhân viên"
            :limit="10"
            @select="onSelectedEmployee"
            @change="onInputChangeEmployee"
          >
            <template #custom="{ suggestion }">
              <div>
                <span>{{ suggestion.item.suggestionName }}</span>
              </div>
            </template>
          </Autosuggest>
        </b-form-group>
        <b-row class="mb-2">
          <b-col md="4">
            <b-button
              variant="warning"
              size="sm"
              class="mb-4"
              @click="showDeleteEmpAlert"
            >
              Xóa nhân viên
            </b-button>
          </b-col>
          <b-col md="4">
            <b-row>
              <label style="font-weight: 500">Cửa hàng:</label>
            </b-row>
            <b-row>
              <span v-text="mainModel.billStoreName"></span>
            </b-row>
          </b-col>
          <b-col md="4">
            <b-row>
              <label style="font-weight: 500">Ngày mua:</label>
            </b-row>
            <b-row>
              <span v-text="mainModel.billCreatedAt"></span>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="12">
            <div>
              <b-table bordered hover :fields="fieldEmp" :items="listEmp">
                <template v-slot:head(selected)>
                  <span>
                    <b-form-checkbox
                      v-model="checkAllEmp"
                      size="lg"
                      @change="checkAllEmployee"
                    ></b-form-checkbox>
                  </span>
                </template>
                <template v-slot:cell(selected)="row">
                  <div class="d-flex justify-content-center">
                    <b-form-checkbox
                      v-model="row.item.selected"
                      @change="clickIndexEmployee"
                    ></b-form-checkbox>
                  </div>
                </template>
                <template v-slot:cell(amount)="row">
                  <b-input
                    type="text"
                    size="sm"
                    class="input-style text-right"
                    v-model="row.item.amount"
                  ></b-input>
                  <p
                    style="font-size: 11px; margin-top: 2px"
                    class="font-weight-bold"
                  >
                    ({{ formatMoney(row.item.amount) }})
                  </p>
                </template>
                <template v-slot:cell(actions)="row">
                  <v-icon
                    small
                    class="text-danger"
                    @click="showDeleteAlertEmp(row.item)"
                    v-b-tooltip
                    title="Xóa"
                    >mdi-delete</v-icon
                  >
                </template>
              </b-table>
            </div>
          </b-col>
          <b-col cols="6" md="4"></b-col>
        </b-row>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSubmit()"
          >Lưu</b-button
        >
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal()"
          >Hủy</b-button
        >
      </v-form>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import {
  makeToastFaile,
  currencyMask,
  unMaskPrice,
  formatPrice,
} from '@/utils/common';
import Swal from 'sweetalert2';
import _ from 'lodash';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { xoa_dau } from '../../../utils/common';

export default {
  props: [],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mainModel: {
        billNumber: '',
        billStoreId: null,
        billStoreName: '',
        billCreatedAt: '',
        date: moment().format('DD/MM/YYYY'),
        operatorType: 1,
        amountType: 1,
        sourceType: 1,
        amount: 0,
        description: '',
      },
      listEmp: [],
      fieldEmp: [
        {
          key: 'selected',
          label: '',
          thStyle: { textAlign: 'center', width: '5%' },
        },
        {
          key: 'code',
          label: 'MSNV',
          thStyle: { width: '20%' },
        },
        {
          key: 'fullName',
          label: 'Tên nhân viên',
          thStyle: { width: '40%' },
        },
        {
          key: 'amount',
          label: 'Giá trị',
          tdClass: 'text-right',
          thStyle: { width: '30%' },
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      selected: [],
      checkAllEmp: false,
      mask: currencyMask,
      filteredOptionEmployee: [],
      optionEmployee: [],
      searchEmployee: '',
    };
  },
  methods: {
    showModal: function () {
      this.fetchEmployee();
      this.listEmp = [];
      this.mainModel = {
        billNumber: '',
        billStoreId: null,
        billStoreName: '',
        billCreatedAt: '',
        date: moment().format('DD/MM/YYYY'),
        operatorType: 1,
        amountType: 1,
        sourceType: 1,
        amount: 0,
        description: '',
      };
      this.$refs['add-by-bill-modal'].show();
    },
    hideModal: function () {
      this.$refs['add-by-bill-modal'].hide();
    },
    getEmployeeInBill() {
      if (!this.mainModel.billNumber) {
        return makeToastFaile('Vui lòng nhập mã hóa đơn!');
      }
      ApiService.get(`bills/bill-employees/${this.mainModel.billNumber}`).then(
        (response) => {
          if (response.data.status === 0) {
            return makeToastFaile('Tải thông tin hóa đơn thất bại!');
          }
          const dataRes = response.data.data;
          this.mainModel.billStoreId = dataRes.storeId;
          this.mainModel.billStoreName = dataRes.storeName;
          this.mainModel.billCreatedAt = dataRes.createdAt;
          this.listEmp = dataRes.employees.map((emp) => {
            return {
              ...emp,
              selected: false,
              amount: 0,
            };
          });
        }
      );
    },
    checkAllEmployee() {
      this.listEmp.forEach((element) => {
        element.selected = this.checkAllEmp;
      });
    },
    clickIndexEmployee() {
      if (this.checkAllEmp == true) {
        this.checkAllEmp = false;
      } else {
        var count = 0;
        this.listEmp.forEach((element) => {
          if (element.selected == true) {
            count += 1;
            if (count == this.listEmp.length) {
              this.checkAllEmp = true;
            }
          }
        });
      }
    },
    showDeleteEmpAlert: function () {
      const idSelected = this.listEmp
        .filter((emp) => emp.selected)
        .map((x) => x.id);

      if (!idSelected || !idSelected.length) {
        this.makeToastFaile('Vui lòng chọn nhân viên cần xóa!');
        return;
      }

      Swal.fire({
        title: 'Xóa danh sách nhân viên đã chọn!',
        text: 'Bạn có chắc muốn xóa các nhân viên đã chọn này khỏi cộng trừ theo cửa hàng không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deletedEmployee(idSelected);
        }
      });
    },
    deletedEmployee(empIds) {
      this.listEmp = _.remove(this.listEmp, function (emp) {
        return !empIds.includes(emp.id);
      });
    },
    onSubmit() {
      this.listDetailByEmp = [];
      const date = this.mainModel.date
        ? moment(this.mainModel.date, 'DD/MM/YYYY').valueOf()
        : null;
      const amount = this.mainModel.amount
        ? unMaskPrice(this.mainModel.amount)
        : 0;
      if (amount <= 0) {
        makeToastFaile('Vui lòng nhập giá trị phát sinh hoa hồng!');
        return;
      }
      this.listEmp.forEach((emp) => {
        const amountValue = emp.amount ? unMaskPrice(emp.amount) : 0;
        const item = {
          date: date,
          employeeId: emp.id,
          employeeName: emp.fullName,
          operatorType: this.mainModel.operatorType === '3' ? 2 : 1,
          amountType: this.mainModel.amountType,
          amount: amountValue,
          description: this.mainModel.description,
          storeId: this.mainModel.billStoreId,
          sourceType: this.mainModel.sourceType,
        };
        this.listDetailByEmp.push(item);
      });
      const payload = {
        listDetail: this.listDetailByEmp,
      };
      this.$emit('onSubmit', payload);
    },
    showDeleteAlertEmp: function (item) {
      Swal.fire({
        title: 'Xóa nhân viên!',
        text: 'Bạn có chắc muốn xóa nhân viên này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.listEmp = _.reject(this.listEmp, (x) => {
            return x.id == item.id;
          });
        }
      });
    },
    assignValue() {
      let amount = this.mainModel.amount
        ? unMaskPrice(this.mainModel.amount)
        : 0;
      if (this.mainModel.sourceType === '3') {
        amount = amount / this.listEmp.length;
      }
      this.listEmp = this.listEmp.map((emp) => {
        return {
          ...emp,
          amount: this.roundMoney(amount),
        };
      });
    },
    roundMoney(value) {
      const tmpValue = Math.round(value / 100);
      return tmpValue * 100;
    },
    formatMoney(value) {
      return formatPrice(value);
    },
    fetchEmployee: async function () {
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        this.optionEmployee = employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          return {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: xoa_dau(element.name),
          };
        });
        this.filteredOptionEmployee = [...this.optionEmployee];
      });
    },
    onSelectedEmployee(option) {
      const empDetail = {
        id: option.item.id,
        code: option.item.code,
        fullName: option.item.name,
        selected: false,
        amount: 0,
      };
      this.listEmp.push(empDetail);
      this.searchEmployee = '';
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [...filteredData];
    },
  },
  components: {
    Autosuggest,
  },
};
</script>

<style>
.center-label label {
  margin-bottom: 0px;
}
</style>
